<!--
 * @Description:车辆用户管理系统 运维管理 优惠活动 优惠活动配置规则 cuDiscountAcRule
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-07-22 10:28:30
 * @LastEditors: zhoucheng
-->
<template>
  <div class="dt-cuDiscountAcRule">
    <el-row class="dt-back">
      <i class="el-icon-back"
         @click="handleBack"></i>
      <span>优惠规则配置</span>
    </el-row>
    <el-row class="topSelect">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="规则名称">
              <el-input v-model="searchForm.activityName"
                        clearable
                        placeholder="请输入规则名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="规则类型">
              <el-select v-model="searchForm.discountType"
                         placeholder="请选择"
                         clearable>
                <el-option v-for="item in discountTypeList"
                           :key="item.value"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <el-button type="primary"
                       @click="onSubmit">查 询</el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="info"
                       @click="resetForm"
                       style="margin-left:17px;">重 置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <el-button type="success"
                   class="tableTitleButton"
                   @click="add">
          配置新增
        </el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :paginationConfig='paginationConfig'
                  :tableConfig='tableConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button @click="remove(scope.row)"
                             type="text"
                             size="medium">删除</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 弹窗 dialogType= 0 新增 1编辑 2详情 -->
    <el-dialog title="新增优惠规则配置"
               :visible.sync="distributeVisible"
               append-to-body
               :close-on-click-modal="false"
               @close="popClose">
      <el-form :inline="true"
               :model="dialogSearchForm">
        <el-row class="popRow">
          <el-col :span="9">
            <el-form-item label-width="90px"
                          label="规则名称">
              <el-input v-model="dialogSearchForm.activityRuleName"
                        clearable
                        placeholder="请输入规则名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label-width="90px"
                          label="规则类型">
              <el-select v-model="dialogSearchForm.discountType"
                         placeholder="请选择"
                         clearable>
                <el-option v-for="item in discountTypeList"
                           :key="item.value"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button type="primary"
                       @click="dialogOnSubmit">查 询</el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="info"
                       @click="dialogResetForm"
                       style="margin-left:17px;">重 置</el-button>
          </el-col>
        </el-row>
        <!-- 表格 -->
        <dt-table :tableColumnList="dialogTableColumnList"
                  :data="dialogTableList"
                  :map="dialogListMap"
                  :paginationConfig='dialogPaginationConfig'
                  :tableConfig='dialogTableConfig'
                  @getList="dialogGetList"
                  :tableFun='tableFun'
                  ref="moreSelect">
          <template slot="isFirst">
            <el-table-column type="selection"
                             :reserve-selection="true"></el-table-column>
          </template>
        </dt-table>
        <el-row class="popRow">
          <el-form-item>
            <el-button type="primary"
                       @click="submitForm">确 认</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { dtTable },
  data () {
    //这里存放数据
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
        chargCode: '',
        chargName: '',
        operName: '',
      },
      tableColumnList: [
        {
          prop: 'activityRuleName',
          label: '规则名称',
        },
        {
          prop: 'discountType',
          label: '规则类型',
        },
        {
          prop: 'discount',
          label: '优惠面额',
        },
        {
          prop: 'useDes',
          label: '优惠说明',
        }
      ],
      tableList: {
        list: [],
      },
      pageNum: 1,
      pageSize: 15,
      // 优惠活动id
      activityId: '',
      //查询表单
      searchForm: {},
      // 优惠规则类型
      discountTypeList: [],
      //是否显示弹窗
      distributeVisible: false,
      //多选方法
      tableFun: {
        'selection-change': this.handleChange
      },
      dialogTableConfig: { 'row-key': this.getRowKey, border: true },
      dialogPaginationConfig: { layout: 'prev,pager,next,jumper', total: 0 },
      dialogListMap: {
        pageNum: 1,
        pageSize: 5,
        chargCode: '',
        chargName: '',
        operName: '',
      },
      dialogTableColumnList: [
        {
          prop: 'activityRuleName',
          label: '规则名称',
        },
        {
          prop: 'discountType',
          label: '规则类型',
        },
        {
          prop: 'discount',
          label: '优惠面额',
        },
        {
          prop: 'useDes',
          label: '优惠说明',
        }
      ],
      dialogTableList: {
        list: [],
      },
      //弹窗查询表单
      dialogSearchForm: {},
      //表格多选
      moreChoseList: [],

      //新增表单
      popForm: {

      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.activityId = this.$route.query.activityId
    this.getDiscountTypeList()
    this.queryTableList()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 优惠规则类型下拉框 F8898BDD4BCF4W12ABJKFSHGRFOUIGHLFCNFFDJDLIFHRFO
    getDiscountTypeList () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'F8898BDD4BCF4W12ABJKFSHGRFOUIGHLFCNFFDJDLIFHRFO',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.discountTypeList = response.resultEntity
      })
    },
    //初始化表格数据
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum
      this.searchForm['pageSize'] = this.pageSize
      this.searchForm['activityId'] = this.activityId
      this.$cuDiscountActivity.selectByActivity(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.tableList.list.forEach(item => {
          if (item.discountType == 0) {
            item.discount = 0 + '-' + item.discount
          } else {
            item.discount = item.discount + '-' + item.discount
          }

        })
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    //返回
    handleBack () {
      this.$router.go(-1)
    },
    //查询
    onSubmit () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    //重置
    resetForm () {
      this.searchForm = {}
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    //新增按钮
    add () {
      this.distributeVisible = true
      this.dialogInitialization()
    },
    //弹窗列表
    dialogInitialization () {
      this.dialogSearchForm['pageNum'] = this.pageNum
      this.dialogSearchForm['pageSize'] = 5
      this.$cuDiscountRule.getList(this.dialogSearchForm).then(res => {
        this.dialogTableList.list = res.resultEntity.list
        this.dialogTableList.list.forEach(item => {
          if (item.discountType == 0) {
            item.discount = 0 + '-' + item.discount
          } else {
            item.discount = item.discount + '-' + item.discount
          }

        })
        this.dialogPaginationConfig.total = res.resultEntity.total
      }).catch()
    },
    //弹窗查询
    dialogOnSubmit () {
      this.pageNum = 1
      this.dialogListMap.pageNum = 1
      this.dialogInitialization()
    },
    //弹窗重置
    dialogResetForm () {
      this.dialogSearchForm = {}
      this.pageNum = 1
      this.dialogListMap.pageNum = 1
      this.dialogInitialization()
    },
    //弹窗分页
    dialogGetList (val) {
      this.pageNum = val.pageNum
      this.dialogInitialization()
    },
    //弹窗
    getRowKey (row) {
      return row.activityRuleId
    },
    //弹窗表格多选按钮
    handleChange (val) {
      console.log(val, '++++++++++++');
      let arr = []
      val.forEach(item => {
        arr.push({
          activityId: this.activityId,
          activityRuleId: item.activityRuleId
        })
      })
      this.moreChoseList = arr
      console.log('this.moreChoseList', this.moreChoseList);
    },
    //弹窗确认
    submitForm () {
      if (this.moreChoseList.length > 0) {
        this.addRequest()
      } else {
        this.$message.error("请选择优惠规则")
      }
    },
    // 新增请求
    addRequest () {
      let info = this.moreChoseList
      this.$cuDiscountActivity.insertRule(info).then(() => {
        this.$message({
          type: 'seccess',
          message: '配置新增规则成功'
        })
        this.distributeVisible = false
        this.queryTableList()
      }
      )
    },
    //删除
    remove (row) {
      this.$confirm('是否确定删除该优惠规则?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cuDiscountActivity.deleteRule({ activityRuleId: row.activityRuleId }).then(() => {
          this.$message({
            type: 'seccess',
            message: '删除规则成功'
          })
          this.queryTableList()
        })
      }).catch()
    },
    // 关闭弹窗
    popClose () {
      this.$refs.moreSelect.$children[0].clearSelection()
    }
  }
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
$topSelectHeight: 80px;
.dt-cuDiscountAcRule {
  height: 100%;
  .dt-back {
    color: #bae8ff;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
  }
  .topSelect {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 21px;
    .topButton {
      display: flex;
      padding-left: 91px;
      // margin-top: 16px;
    }
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.popRow {
  margin-bottom: 25px;
}
</style>